@import "core/index.scss";
.page.checkout {
	margin: 2.3rem 0 0;
	// margin-bottom: 6rem;
	min-height: 35rem;

	+ .section.footer {
		margin-top: 6rem;
	}

	@include media {
		// margin-bottom: 0;
		// padding-bottom: 12rem;
		padding-bottom: calc(2rem + var(--safe-area-insets-bottom, env(safe-area-inset-bottom)));
	}

	.checkout-wrap {
		@include mediaMin {
			display: flex;
		}
	}

	.checkout-left {
		@include mediaMin {
			width: calc(100% - 26rem);
	
			&.full {
				width: 100%;
			}
		}

		.checkout-section {
			+ .checkout-section {
				margin-top: 5.4rem;
			}
		}
	}

	.checkout-right {
		@include mediaMin {
			width: 24rem;
			margin-left: 2rem;
			position: relative;
			z-index: 3;

			&.sticky {
				.checkout-right-container {
					position: fixed;
					top: 2rem;
					right: 0;
				}
	
				&.sticky-bottom {
					.checkout-right-container {
						top: auto;
						position: absolute;
						bottom: 0;
						left: 0!important;
						right: auto;
					}
				}
			}
		}

		@include media {
			margin-top: 4rem;

			.checkout-right-container {
				width: auto!important;
			}
		}

		> * + * {
			margin-top: 2rem;
		}
	}

	// Right Sections

	.checkout-sum {
		background-color: $color-white;
		box-shadow: $shadow-big;
		border: .1rem solid $color-gray-light;
		border-radius: $radius-general;
		// overflow: hidden;

		.sum-header {
			background-color: $color-blue-bg;
			border-bottom: .1rem solid $color-gray-light;
			padding: 2rem;
		}

			.header-feedback {
				margin-bottom: 2rem;
			}

			.header-title {
				color: $color-text-light;
				font-size: 1.2rem;

				strong {
					@include font-semibold;
					color: $color-text;
					text-transform: uppercase;
				}
			}

			.header-total {
				margin-top: 2rem;
			}

			.header-agreements {
				.checkwrap input + label {
					font-size: 1.2rem;
					line-height: 2rem;
				}
			}

			.header-cta {
				margin-top: 2rem;
			}

		.sum-services {
			padding: 1.8rem;
		}

			.services-service {
				@include font-regular;
				display: flex;
				flex-flow: row wrap;
				align-items: center;
				justify-content: space-between;
				color: $color-text-light;

				+ .services-service {
					margin-top: 1.2rem;
				}

				strong {
					font-weight: inherit;
					color: $color-text-lighter;
					max-width: 75%;
    				flex: 1 0 0;
					padding-right: 1rem;
					line-height: 1.2em;
				}

				.pricebox {
					font-size: inherit;
					
					&.discount {
						color: $color-orange;
					}
				}
			}

				.service-clearbtn {
					color: $color-text-light;
					margin-left: .5rem;
					font-size: .8em;
					transition: opacity $transition-hover ease;

					&:hover {
						color: $color-primary;
					}
				}

			.services-promocode {
				background-color: $color-pink-bg-light;
				margin-top: 1rem;
				border-radius: $radius-general;
				padding: 1rem;
			}
			
				.promocode-title {
					display: block;
					color: $color-pink;
					margin-bottom: .5rem;
				}

				.promocode-description {
					color: $color-text-light;
					margin: .5rem 0;
					font-size: 1.2rem;
				}

		.sum-btnwrap {
			padding: 1.8rem;
		}

		.sum-bottomcontrols {
			border-top: .1rem solid $color-gray-light;
			padding: 1.6rem 1.8rem;
		}

		.sum-promocodes {
			.babymall_select__option {
				span {
					font-size: 1.2rem;
					display: block;
					margin-top: .3rem;
					color: $color-text-lighter;
				}
			}

			.babymall_select__single-value {
				span {
					display: none;
				}
			}

			+ .btn {
				margin-top: 1.5rem;
			}
		}
	}

	.checkout-storebanner {
		display: flex;
		background-color: $color-blue-bg;
		color: $color-blue-medium;
		align-items: center;
		padding: 1.5rem;
		font-size: 1.4rem;
		line-height: 1.3em;

		.storebanner-icon {
			font-size: 3.2rem;
			margin-left: 1.5rem;
			opacity: .8;
		}
	}

	// Left Sections

	.checkout-noninstallmentwarning {
		.noninstallmentwarning-list {
			margin-top: 1.5rem;
			// display: flex;
			// justify-content: flex-start;

			li {
				// width: calc(50% - .5rem);
				padding: 1rem;
				background-color: $color-white;
				border-radius: $radius-general;

				+ li {
					margin-top: 1rem;
				}
			}
		}
	}

	.checkout-feedback {
		width: 100%;
		margin-bottom: 2rem;
	}

	.checkout-section {
		.section-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 2rem;

			.section-title {
				margin-bottom: 0;
			}
		}

		.section-title {
			@include font-semibold;
			font-size: 2.4rem;
			margin-bottom: 2rem;
			display: block;

			&.big {
				@include font-bold;
			}

			&.small {
				font-size: 1.5rem;
			}
		}

		.tabs-labels {
			margin-bottom: 2rem;
		}

		// .section-tab {
		// 	// padding-top: 2rem;
		// }

			.tab-btn {
				margin-top: 2rem;
			}

		.section-emptymessage {
			border: .1rem solid $color-gray-light;
			padding: 2rem;
			text-align: center;
			border-radius: $radius-general;
		}
	}

	.checkout-basket {
		.basket-clearbtn {
			text-decoration: underline;
			color: $color-text-light;

			&:disabled {
				opacity: .5;
				cursor: default;
			}
		}
		
		.basket-empty {
			text-align: center;
			padding: 4rem 0;
			color: $color-text-light;
			border: .1rem solid $color-gray;
			border-radius: $radius-general;
		}

		// .basket-list {}

			.list-product {
				padding: 2rem 0;
				border-bottom: .1rem solid $color-gray-light;

				@include media {
					&:last-child {
						border-bottom: 0;
						padding-bottom: 0;
					}
				}
			}
		
		.basket-deliveryinfo {
			@include font-semibold;
			padding: 1rem 1rem;
			background-color: $color-blue-bg;
			border-radius: $radius-general;
			color: $color-blue;
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 5rem;
			margin-bottom: 1rem;
			line-height: 1.4em;

			i {
				font-size: 3rem;
				margin-right: 1rem;
			}

			span {
				@include font-bold;
				background-color: rgba($color-blue, .25);
				padding: .3rem .5rem .2rem;
				border-radius: $radius-big;
				margin: 0 .8rem;
				white-space: nowrap;
			}

			@include media {
				padding: 1rem 2rem;
				align-items: flex-start;

				i {
					font-size: 3.4rem;
				}

				span {
					margin: 0;
					font-size: .9em;
				}

				.cargoinfo-text {
					margin-left: .5rem;
					margin-top: 1rem;
				}
			}
		}

		.basket-cargoinfo {
			@include font-semibold;
			padding: 1rem 1rem;
			background-color: $color-orange-bg;
			border-radius: $radius-general;
			color: $color-orange;
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 5rem;
			margin-bottom: 1rem;
			line-height: 1.4em;

			i {
				font-size: 3rem;
				margin-right: 1rem;
			}

			span {
				@include font-bold;
				background-color: rgba($color-orange, .25);
				padding: .3rem .5rem .2rem;
				border-radius: $radius-big;
				margin: 0 1rem;
			}

			@include media {
				padding: 1rem 2rem;
				align-items: flex-start;

				i {
					font-size: 3.4rem;
				}

				span {
					margin: 0 .5rem;
				}

				.cargoinfo-text {
					margin-left: .5rem;
					margin-top: 1rem;
				}
			}
		}

		.basket-message {
			@include font-medium;
			background-color: $color-bg;
			text-align: center;
			padding: 2rem;
			border-radius: $radius-general;
			line-height: 1.3em;

			strong {
				color: $color-primary;
			}
		}
	}

	.checkout-campaigns {
		@include media {
			width: calc(100% + 4rem);
			margin: 4rem -2rem 0;
			overflow: hidden;
			padding: 0 4rem 0 2rem;
		}

		.campaigns-slider {
			@include mediaMin {
				@include maskSlider(3.5rem, $color-white);
				width: calc(100% + 9rem);
				margin: 0 -4.5rem;
			}

			@include media {
				overflow: visible;
				width: calc(100% + 2rem);
				margin: 0 -1rem;
			}
			
			.swiper-slide {
				padding: 0 1rem;
				display: flex;
				height: auto;
			}

			.swiper-button-next {
				right: 1rem;
			}

			.swiper-button-prev {
				left: 1rem;
			}
		}

			.campaign-button {
				display: flex;
				flex-direction: column;
				width: 100%;
				border: .1rem solid $color-gray-light;
				padding: 2rem;
				border-radius: $radius-general;
				box-shadow: $shadow-general;
				transition: background-color $transition-hover ease;

				@include mediaMin {
					&:hover {
						background-color: $color-bg-light;
					}
				}
			}

			.campaign-title {
				display: block;
				color: $color-pink;
				margin-bottom: 1rem;

				&.placeholder {
					height: 1.3rem;
					width: 100%;
				}
			}

			.campaign-description {
				font-size: 1.4rem;
				line-height: 1.3em;
				color: $color-text-light;
				max-height: 3.9em;
				overflow: hidden;
				flex: 1 0 0;

				&.placeholder {
					height: 1.6rem;
					width: 80%;
				}
			}

			.campaign-imagewrap {
				width: 100%;
				position: relative;
				padding-bottom: 44%;
				margin-bottom: 2rem;
				background-color: $color-bg;
				border-radius: $radius-general;
				overflow: hidden;
			}

			.campaign-image {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}

			.campaign-ctawrap {
				@include font-medium;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-top: 2rem;

				i {
					margin-left: 1rem;
				}

				.placeholder {
					height: 1.9rem;
					width: 11rem;
				}
			}
	}

	.checkout-lists {
		padding-bottom: 0;
		width: calc(100% + 4rem);
		margin-left: -2rem;
		margin-right: -2rem;

		.tabs-items {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}

	// Page Alternates

	&.checkout-summary {
		.summary-message {
			background-color: $color-pink;
			color: $color-white;
			padding: 4rem 3.6rem;
			margin-bottom: 4rem;
			border-radius: $radius-general;

			@include media {
				padding: 2rem 2rem;
			}
		}

			.message-title {
				@include font-bold;
				font-size: 5rem;
				line-height: 1.3em;

				@include media {
					font-size: 2.4rem;
				}
			}

			.message-links {
				margin-top: 2rem;

				@include mediaMin {
					> * + * {
						margin-left: 1rem;
					}
				}
			}

			.links-link {
				@include media {
					width: 100%;

					+ * {
						margin-top: 1.5rem;
					}
				}
			}

		.summary-products {
			.products-list {
				border: .1rem solid $color-gray-light;
				padding: 0 2rem;
				border-radius: $radius-general;
				box-shadow: $shadow-input;
			}

				.list-product {
					padding: 2rem 0;

					+ .list-product {
						border-top: .1rem solid $color-gray-light;
					}
				}
		}

		.summary-info {

			.info-content {
				padding: 2.5rem 2rem;
			
				@include mediaMin {
					border-radius: $radius-general;
					box-shadow: $shadow-input;
					border: .1rem solid $color-gray-light;
				}

				@include media {
					border-top: .1rem solid $color-gray-light;
					border-bottom: .1rem solid $color-gray-light;
					width: calc(100% + 4rem);
					margin: 0 -2rem;
				}
			}
			
				.content-data {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
				}

					.data-section {
						width: calc(50% - 1rem);
						display: flex;
						color: $color-text-light;
						padding: .5rem 0;

						@include media {
							display: block;
						}

						strong {
							// width: 30%;
							color: $color-text;

							@include media {
								display: block;
							}
						}

						> span {
							flex: 1 0 0;
							margin-left: 1rem;

							@include media {
								flex: none;
								display: block;
								margin: 1rem 0 0;
							}
						}
					}

				.content-bottom {
					background-color: $color-bg-light;
					border-radius: $radius-general;
					margin-top: 2rem;
					padding: 2rem;
					display: flex;
					justify-content: space-between;	
					
					@include media {
						display: block;
					}

					.bottom-section {
						display: flex;
						flex-direction: column;
						
						@include mediaMin {
							width: calc(50% - 1rem);
						}

						@include media {
							+ .bottom-section {
								margin-top: 2rem;
							}
						}
					}

						.section-title {
							color: $color-text-light;
							font-size: 2rem;
							display: block;
							margin-bottom: 2rem;
						}

						.section-content {
							flex: 1 0 0;
							display: flex;
							justify-content: center;
							flex-direction: column;

							&.white {
								background-color: $color-white;
								border-radius: $radius-general;
								padding: 2rem;
							}
						}

					// .bottom-addresses {
					// }

						.addresses-address {
							+ .addresses-address {
								margin-top: 3rem;
							}
						}

							.address-title {
								display: block;
								margin-bottom: .5rem;
							}

							.address-info {
								color: $color-text-light;
								line-height: 1.3em;
								font-size: 1.4rem;
							}

							.address-contact {
								@include font-medium;
								color: $color-text-lighter;
								margin-top: .5rem;
							}

					// .bottom-payment {}

						.payment-title {
							color: $color-text-lighter;
							margin-bottom: .4rem;
							display: block;
						}

						.payment-services {
							margin-top: 3rem;
						}

							.services-service {
								display: flex;
								justify-content: space-between;
								color: $color-text-light;

								+ .services-service {
									margin-top: .6rem;
								}

								strong {
									color: $color-text-lighter;
								}
							}

						.payment-total {
							display: flex;
							align-items: center;
							justify-content: space-between;
							border-top: .1rem solid $color-gray-light;
							padding-top: 2rem;
							margin-top: 3rem;

							strong {
								font-size: 1.8rem;
							}

							.pricebox {
								font-size: 2rem;
							}
						}

						.payment-info {
							@include font-semibold;
							margin-top: .5rem;
							display: flex;
							justify-content: flex-start;
							align-items: center;

							&.account {
								.info-detail {
									@include font-medium;
									max-width: 30%;
									color: $color-text-lighter;
									font-size: 1.4rem;
								}
							}
						}

							.info-image {
								width: 6rem;
								height: 3rem;
								border: .2rem solid $color-gray-light;
								border-radius: $radius-general;
							}

							.info-data {
								margin-left: 2rem;
								flex: 1 0 0;
							}

							.info-detail {
								margin-left: 2rem;
								text-align: right;
							}
				}

				.content-agreements {
					@include font-medium;
					padding: 2rem 0 0;
					font-size: 1.8rem;
					color: $color-text-light;

					> * + * {
						margin-left: 2rem;
					}
				}
		}
	}

	// Select Inputs
	.checkout-selectinput {
		.label-content {
			width: calc(100% - 3.5rem);
		}
		
		.selectinput-optinfo {
			display: flex;
			align-items: center;
			padding: 1rem 0;
			transition: opacity $transition-hover ease;

			@include media {
				display: block;
				font-size: 1.5rem;
				padding: 1.4rem 0 1.5rem;
			}

			&.deleting {
				opacity: .5;
			}
		}
			.optinfo-text {
				@include mediaMin {
					flex: 1 0 0;
					max-width: calc(100% - 16.5rem);
				}
			}

				.optinfo-title {
					@include font-semibold;
					display: block;
				}

				.optinfo-description {
					@include font-regular;
					color: $color-text-light;
					width: 100%;
					line-height: 1.3em;

					@include media {
						margin-top: 1rem;

						> * {
							display: block;
							> * + * {
								margin-left: .5rem;
							}

							+ * {
								margin-top: 1rem;
							}
						}
					}

					@include mediaMin {

						margin-top: .5rem;
						> * {
							display: inline-block;
							vertical-align: bottom;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
	
							> * {
								+ * {
									margin-left: .5rem;
								}
							}
	
							+ * {
								margin-left: 2rem;
							}
						}
	
	
						.description-billing {
							max-width: 25%;
	
							+ .description-address {
								max-width: 40%;
	
								+ .description-contact {
									max-width: 27%;
								}
							}
						}
	
						.description-address {
							max-width: 60%;
						}
	
						.description-contact {
							max-width: 36%;;
						}
					}
				}
					
				
			.optinfo-controls {
				display: flex;
				align-items: center;

				@include media {
					margin-top: 2rem;
					justify-content: space-between;
				}

				> * + * {
					margin-left: 2rem;
				}
			}

		@include media {
			.checkwrap {
				input + label {
					align-items: flex-start;

					.label-check {
						margin-top: 1rem;
					}
				}
			}
		}

	}

	.checkout-cargoinput.inputwrap {
		.checkwrap {
			width: 38rem!important;

			input {
				+ label {
					align-items: flex-start;
					padding: 2rem;
					// border: .1rem solid $color-gray-light;
					// border-radius: $radius-general;
				}
			}
		}

		// .cargoinput-opt { 
		// 	padding: 2rem 0;
		// }

			.opt-head {
				display: flex;
				justify-content: space-between;
				align-items: center;

				&:last-child {
					margin-top: .3rem;
				}
			}

			.opt-info {
				color: $color-text-light;
				margin-top: .5rem;
				line-height: 1.3em;

				span {
					@include font-medium;
					color: $color-green;
					text-decoration: underline;
				}
			}
	}

	// Yardım Kurumları
	.checkout-charityselection {
		margin-top: 6rem;

		.checkout-charityinput {
			.checkwrap {
				width: calc(33.333333% - 1rem)!important;
				width: calc((100% / 3) - 1rem)!important;

				@include media {
					width: 100%!important;
				}
	
				input {
					+ label {
						align-items: center;
						padding: 2rem;
					}
				}
			}
	
				.opt-head {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
	
				.opt-info {
					color: $color-text-light;
					margin-top: .5rem;
					line-height: 1.3em;
	
					span {
						@include font-medium;
						color: $color-green;
						text-decoration: underline;
					}
				}
		}
	}

	.checkout-paymentopts {
		border: .1rem solid $color-gray-light;
		border-radius: $radius-general;
		box-shadow: $shadow-input;
		position: relative;
		
		.paymentopts-blockmsg {
			position: absolute;
			background-color: rgba($color-white, .95);
			border-radius: $radius-general;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: $color-text-light;
			z-index: 2;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.paymentopts-opt {
			
			&:not(:first-child) {
				border-top: .1rem solid $color-gray-light;
			}

			.input-opts {
				border: none;
				border-radius: none;
				box-shadow: none;
			}

			.checkwrap {
				input{
					&:checked {
						+ label {
							background-color: $color-white;
						}
					}

					+ label {
						height: 7rem;
					}
				}
			}
		}

		.paymentopts-section {
			margin: 0 2rem 2rem;
			border: .1rem solid $color-gray-light;

			&.textarea {
				border: none;
				font-size: 1.4rem;
				color: $color-text-light;
			}
		}

			.section-header {
				height: 7rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: .1rem solid $color-gray-light;
				padding: 0 3.6rem;
			}

			.section-content {
				padding: 2rem;
				&.loader-container {
					min-height: 9rem;
				}
			}

		.paymentopts-textarea {
			padding: 2rem;
		}
	}

		// .paymentopts-cardinfo {
		// }

			.cardinfo-cardinput {
				position: relative;
			}

				.cardinput-type {
					position: absolute;
					right: 1.2rem;
					top: 2rem;
					width: 2.2rem;
					height: 2rem;
					background-color: $color-white;
					border-radius: $radius-general;
				}

			.cardinfo-installments {
				margin-top: 3rem;
				background-color: $color-bg;
				padding: 3.6rem 6rem;

				@include media {
					padding: 3rem 2rem;
				}
			}

				.installments-emptymessage {
					padding: 1rem 0;
					color: $color-text-light;
				}

				.installments-title {
					display: block;
					margin-bottom: 2rem;
				}

				.installments-input {
					max-width: 45rem;

					.checkwrap {
						input {
							&:checked + label {
								color: inherit;
								background-color: $color-white;
							}

							+ label {
								background-color: $color-white;
							}
						}
					}
				}

				.installments-opt {
					display: flex;
					align-items: center;
					padding: 1.8rem 0;

					@include media {
						flex-wrap: wrap;
					}
				}

					.opt-text {
						flex: 1 0 0;

						@include media {
							width: 100%;
							margin-bottom: 1rem;
							flex: none;
						}
					}

					.opt-title {
						@include font-semibold;
						display: block;
					}

					.title-promo {
						@include font-regular;
						display: inline-block;
						font-size: 1.2rem;
						padding: .2rem .5rem;
						line-height: 1.2rem;
						background-color: $color-orange;
						margin-left: .5rem;
						color: $color-white;
						border-radius: $radius-small;
						vertical-align: text-bottom
					}

					.opt-description {
						@include font-semibold;
						color: $color-orange;
						display: block;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						margin-top: .1rem;
					}

					.opt-total {
						@include font-regular;
						margin-left: 2rem;
					}

					.opt-fractions {
						@include font-regular;
						color: $color-text-lighter;

						@include mediaMin {
							margin-left: 2rem;
						}
					}

		.paymentopts-bankaccount {
			.bankaccount-input {
				margin-left: 0!important;
				display: block;
	
				.input-opts {
					border: none;
					box-shadow: none;
					border-radius: 0;
				}
	
				.checkwrap {
					input {
						+ label {
							border: none;
							border-radius: 0;
							align-items: flex-start;
							padding-top: 1rem;
							padding-bottom: 1rem;
	
							> .label-check {
								margin-top: .3rem;
							}
						}
					}
				}
	
				// .bankaccountinput-content {
				// }
	
					.content-logo {
						height: 2.8rem;
						display: block;
						margin-bottom: 1rem;
					}
	
					.content-title {
						display: block;
					}
	
					.content-description {
						@include font-regular;
						color: $color-text-light;
						margin-top: .8rem;
					}
			}
		}

}
